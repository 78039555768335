import getConfig from "next/config";
import Head from "next/head";

const {
  publicRuntimeConfig: { cloudinaryDomain },
} = getConfig();

const StructuredData: React.FC<{
  structuredData?:
    | PageStructuredData
    | ArticleStructuredData
    | VideoStructuredData
    | Array<PageStructuredData | ArticleStructuredData | VideoStructuredData>;
}> = ({ structuredData }) => {
  if (!structuredData) return null;

  const structuredDataArray = Array.isArray(structuredData) ? structuredData : [structuredData];

  const [schema] = structuredDataArray;

  const entryUrl =
    schema["@type"] === "WebPage"
      ? schema.url
      : typeof schema.mainEntityOfPage === "string"
      ? schema.mainEntityOfPage
      : schema.mainEntityOfPage?.url;
  return (
    <Head>
      {schema["@type"] === "WebPage" ? (
        <>
          <meta property="og:type" content="website" />
        </>
      ) : (
        <>
          <meta
            property="og:image"
            content={`${cloudinaryDomain}/image/fetch/w_1200,h_627,c_fill,q_auto,f_auto/${schema.thumbnailUrl}`}
          />
        </>
      )}
      {entryUrl && (
        <>
          <meta property="og:url" content={entryUrl} />
          <link rel="canonical" href={entryUrl} />
        </>
      )}

      <meta name="description" content={schema.description} />
      <meta property="og:description" content={schema.description} />
      {schema["@type"] === "NewsArticle" && (
        <>
          <meta property="article:published_time" content={schema.datePublished} />
          <meta property="article:modified_time" content={schema.dateModified} />
        </>
      )}
      {structuredDataArray.map((jsonLdEntry) => (
        <script
          key={jsonLdEntry["@type"]}
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(jsonLdEntry),
          }}
        />
      ))}
    </Head>
  );
};

export default StructuredData;
