import type { NextPage, NextPageContext } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import { useQuery } from "@tanstack/react-query";
import PageHeader from "@common/PageHeader/PageHeader";
import DynamicContent from "@pageContent/DynamicContent";
import OopsPage from "@pageContent/OopsPage";
import StructuredData from "@pageContent/StructuredData";
import PageContainer from "@pageContent/common/PageContainer/PageContainer";
import DynamicPageSkeleton from "@pageContent/common/Skeletons/DynamicPageSkeleton";
import { tableNames } from "@constants/consts";
import { useHostConfig } from "@utils/common/getHostConfig";
import { useNoticeError } from "@utils/common/newRelic";
import usePageView from "@utils/common/usePageView";
import { fetchByEndpoint } from "@utils/pageContent/fetchData";

const getPage = async (slug: string): Promise<Page | CategoryEntry | null> => {
  const [page, category] = await Promise.all([
    fetchByEndpoint<Page>(`/api/page/${slug}`),
    slug !== "home" ? fetchByEndpoint<Page>(`/api/category/${slug}`) : null,
  ]);
  return page || category;
};

const DynamicPage: NextPage<{ initialPage?: Page | CategoryEntry }> = ({ initialPage }) => {
  const { query } = useRouter();
  const { outletName } = useHostConfig();

  const slugs = Array.isArray(query.slugs) ? query.slugs : [];
  const slug = slugs[slugs.length - 1];

  const pageQueryKey = ["page", slug];

  const {
    isLoading,
    data: page,
    isError,
    error,
  } = useQuery({
    queryKey: pageQueryKey,
    queryFn: () => getPage(slug || "home"),
    initialData: initialPage,
  });

  useNoticeError(error, { queryKey: pageQueryKey.join(",") });
  usePageView({ isLoading, error, data: page });

  if (isLoading && !initialPage) return <DynamicPageSkeleton />;
  if (!page) return <OopsPage is404={!isError} />;

  const { title, header, items, structuredData } = page;

  return (
    <>
      <Head>
        <title>{`${title} | ${outletName}`}</title>
        <meta property="og:title" content={`${title} | ${outletName}`} />
        <meta property="og:type" content="website" />
      </Head>
      <StructuredData structuredData={structuredData} />
      {header && <PageHeader {...header} />}
      <PageContainer>{items?.map((item) => <DynamicContent key={item.id} {...item} />)}</PageContainer>
    </>
  );
};

DynamicPage.getInitialProps = async ({
  req,
  res,
  query,
  dynamoDBUtils,
}: NextPageContext & { dynamoDBUtils: { getItemBySlug: <T>(tableName: string, slug: string) => T } }) => {
  if (!res || !req) return {};

  const slugs = Array.isArray(query.slugs) ? query.slugs : [];

  const lastSlug = slugs.length ? slugs[slugs.length - 1] : "home";
  try {
    const [page, category] = await Promise.all([
      dynamoDBUtils.getItemBySlug<Page>(tableNames.page, lastSlug),
      lastSlug !== "home" ? dynamoDBUtils.getItemBySlug<Category>(tableNames.category, lastSlug) : null,
    ]);

    const initialPage = page || category;
    if (initialPage) {
      const [currentUrl] = req.url?.split("?") || [];
      if (initialPage.url !== currentUrl) {
        res.writeHead(301, { Location: initialPage.url });
        res.end();
        return {};
      }
      return { initialPage };
    }

    // check whether slug matches article
    const article = await dynamoDBUtils.getItemBySlug<Article>(tableNames.article, lastSlug);
    if (article) {
      res.writeHead(301, { Location: article.redirectUrl || article.url });
      res.end();
      return {};
    }

    res.statusCode = 404;
    return {};
  } catch (error) {
    console.log(error);
    res.statusCode = 500;
  }
  return {};
};

export default DynamicPage;
